<template>
  <div class="bg-gray-800/50">
    <div class="flex h-10 items-center px-5">
      <InertiaLink href="/" class="flex-shrink-0" disabled="disabled">
        <img src="../images/logo-sm.svg" class="h-6 flex-shrink-0" />
      </InertiaLink>
      <BasePriorityBar :items="items" class="ml-6">
        <BasePriorityBarItem class="flex items-center whitespace-nowrap" v-for="item in items" :key="item.id">
          <div class="relative items-center justify-center pr-4">
            <a
              :href="item.href"
              class="flex items-center text-xs font-medium"
              :class="isCurrentTab(item) ? 'text-white' : 'text-gray-300 hover:text-white'"
              :key="item.id"
              :target="item.label === 'Sidekiq' ? '_blank' : null"
            >
              <div
                :class="[
                  'mr-1.5 h-4 w-0.5 rounded-full from-blue-500 to-blue-600',
                  isCurrentTab(item) ? 'bg-gradient-to-t' : ''
                ]"
              ></div>
              <div class="flex items-center" v-html="item.label"></div>
            </a>
          </div>
        </BasePriorityBarItem>
      </BasePriorityBar>
      <div class="flex items-center gap-4 px-5">
        <div class="flex items-center gap-4 text-xs font-medium">
          <div class="cursor-pointer" @click.prevent="signOut">Logout</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onBeforeMount } from 'vue';
import { Link as InertiaLink, router, usePage } from '@inertiajs/vue3';
import { useStore } from 'vuex';

const $page = usePage();
const $store = useStore();

const url = computed(() => $page.url);

const props = defineProps({
  apiToken: { type: String, default: '' },
  permissions: { type: Array, default: () => [] },
  roles: { type: Array, default: () => [] },
  userToken: String,
  streamKey: String,
  giphyKey: String,
  impersonating: Boolean,
  team: { type: Object, default: null },
  currentUser: { type: Object, default: null },
  frontendUrl: String
});

const items = computed(() => {
  let nav = [{ id: 1, label: 'Admin', href: '/admin' }];
  if ($store.getters.roles.includes('super_admin')) nav.push({ id: 2, label: 'Sidekiq', href: '/sidekiq' });
  return nav;
});

onBeforeMount(() => {
  $store.dispatch('setUser', {
    roles: props.roles,
    permissions: props.permissions,
    team_name: props.team.name,
    user: props.currentUser
  });
  $store.dispatch('setFrontendUrl', props.frontendUrl);
});

function isCurrentTab(navItem, subNav = false) {
  return url.value.startsWith(navItem.href);
}

function signOut() {
  window.location.replace('/admin_users/sign_out');
}
</script>
